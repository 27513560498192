import { graphql, navigate } from "gatsby"
import _ from "lodash"
import React, { useEffect } from "react"
import { Helmet } from "react-helmet"

import { CaseStudyCard } from "../components/Card/types"
import ItemRows from "../components/ItemRows"
import { PageContentsWrapper, PageLayout } from "../components/PageLayout"
import SectionTitle from "../components/SectionTitle"
import {
  createStaticContent,
  formatStaticContent,
  getPageTitle,
  transformToArray,
  transformToMap,
} from "../data/transform"

export const query = graphql`
  query WorkPageQuery {
    locations: allAirtable(filter: { table: { eq: "Locations" } }) {
      ...LocationsFragment
    }
    sports: allAirtable(filter: { table: { eq: "Sports" } }) {
      ...SportsFragment
    }
    clients: allAirtable(filter: { table: { eq: "Clients" } }) {
      ...ClientsFragment
    }
    products: allAirtable(filter: { table: { eq: "Products" } }) {
      ...ProductsFragment
    }
    caseStudies: allAirtable(
      filter: { table: { eq: "CaseStudies" }, data: { Disable: { ne: true } } }
    ) {
      ...CaseStudiesFragment
    }
    static_content_all: allAirtable(
      filter: { table: { eq: "StaticContent" }, data: { page: { eq: "all" } } }
    ) {
      ...StaticContentFragment
    }
    static_content_work: allAirtable(
      filter: { table: { eq: "StaticContent" }, data: { page: { eq: "work" } } }
    ) {
      ...StaticContentFragment
    }
  }
`

export const OurWorkPage = props => {
  const locations = transformToArray(
    props.data,
    "locations",
    record => record.inFooter
  )
  const cases = transformToArray(props.data, "caseStudies")

  const sportMap = transformToMap(props.data, "sports")
  const clientMap = transformToMap(props.data, "clients")
  const productMap = transformToMap(props.data, "products")
  const content = createStaticContent(props.data, "work")

  return (
    <PageLayout locations={locations} static_content={content}>
      <Helmet>
        <title>{getPageTitle(content)}</title>
      </Helmet>
      <PageContentsWrapper>
        <SectionTitle
          title={formatStaticContent(content, "work-title")}
          desc={formatStaticContent(content, "work-desc")}
          bg_icon="lineart1"
        />
        <ItemRows child_limit={0}>
          {_.map(cases, (caseStudy, i) => (
            <CaseStudyCard
              key={i}
              caseStudy={caseStudy}
              clientMap={clientMap}
              productMap={productMap}
              sportMap={sportMap}
            />
          ))}
        </ItemRows>
      </PageContentsWrapper>
    </PageLayout>
  )
}

export default OurWorkPage
